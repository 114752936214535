import React, { useState } from "react";
import FormSelector from "./FormSelector";
import { FORM_COMPONENTS } from "../Constants/Constants";

const Dashboard = () => {
  const [selectedForm, setSelectedForm] = useState("");

  return (
    <div className="container">
      <div className="box w-full">
        <h1 className="my-5 text-center text-3xl font-semibold ">
          CSM-Dashboard
        </h1>
        <FormSelector onChange={setSelectedForm} />
        {FORM_COMPONENTS[selectedForm] ?? null}
      </div>
    </div>
  );
};

export default Dashboard;
