const BASE_URL = process.env.REACT_APP_BACK_URL;

const getHeaders = (isFormData) => {
  const authToken = localStorage.getItem("authToken");

  let headers = {};

  if (!isFormData) {
    headers["Content-Type"] = "application/json";
  }

  if (authToken) {
    headers["Authorization"] = `Token ${authToken}`;
  }

  return headers;
};

const getRequestConfig = ({ endpoint, apiRequest, data, params, signal }) => {
  // Check if data is FormData
  const isFormData = data instanceof FormData;

  const requestConfig = {
    method: apiRequest,
    headers: getHeaders(isFormData),
  };

  if (signal) requestConfig.signal = signal;

  let url = new URL(BASE_URL + endpoint);
  if (data) {
    requestConfig.body = isFormData ? data : JSON.stringify(data);
  }

  // if params are present, add them to search params
  if (params) url.search = new URLSearchParams(params).toString();

  return { url, requestConfig };
};

export const fetchApiData = async ({
  endpoint,
  data = null,
  apiRequest = "POST",
  params = null,
  signal,
}) => {
  const { url, requestConfig } = getRequestConfig({
    endpoint,
    apiRequest,
    data,
    params,
    signal,
  });
  try {
    const response = await fetch(url, requestConfig);

    if (!response.ok) {
      const errorData = await response.json();
      const statusCode = response?.status;
      if (statusCode === 401 || statusCode === 403) {
        // Clear the storage and redirect to login page..
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = "/";
      }
      let errorMessage;
      if (errorData.errors && errorData.errors.length > 0) {
        errorMessage = errorData.errors[0].message;
      } else {
        errorMessage =
          errorData || "An error occurred. Please try again later."; //if the response is string and not object
      }
      return { data: null, error: errorMessage };
    }

    let responseData;
    try {
      responseData = await response.json(); // Parse response body as JSON
    } catch (error) {
      console.warn("Unable to parse response JSON:", error);
      responseData = null; // Set responseData to null
    }
    return { data: responseData, error: null };
  } catch (error) {
    return { data: null, error: "Error occurred while fetching from the API." };
  }
};

export const interpolate = function (theString, argumentArray) {
  var regex = /%s/;
  var _r = function (p, c) {
    return p.replace(regex, c);
  };
  return argumentArray.reduce(_r, theString);
};
