import RegistrationForm from "../Components/RegistrationForm";
import ProConversionForm from "../Components/ProConversionForm";
import EnterpriseConversionForm from "../Components/EnterpriseConversionForm";
import { IncreaseFreeTrialForm } from "../Components/IncreaseFreeTrialForm";
import { FreePropertyUpdateForm } from "../Components/FreePropertyUpdateForm";
import { InviteUser } from "../Components/InviteUser";
import CopyTransfer from "../Components/CopyTransfer";
import UpdateContract from "../Components/UpdateContract";
import DownloadUsageSummary from "../Components/DownloadUsageSummary";

export const COMPANY_ENUM = {
  USL: 1,
  GG: 2,
  REXEL: 3,
};

export const FORM_COMPONENTS = {
  REGISTRATION_FORM: <RegistrationForm />,
  PRO_CONVERSION_FORM: <ProConversionForm />,
  ENTERPRISE_CONVERSION_FORM: <EnterpriseConversionForm />,
  ENTERPRISE_CONVERSION_FOR_BEAM_FORM: (
    <EnterpriseConversionForm isBeam={true} />
  ),
  INCREASE_FREE_TRIAL_FORM: <IncreaseFreeTrialForm />,
  FREE_PROPERTY_UPDATE_FORM: <FreePropertyUpdateForm />,
  USAGE_SUMMARY: <DownloadUsageSummary />,
  USAGE_SUMMARY_USL: <DownloadUsageSummary company={COMPANY_ENUM.USL} />,
  USAGE_SUMMARY_GG: <DownloadUsageSummary company={COMPANY_ENUM.GG} />,
  USAGE_SUMMARY_REXEL: <DownloadUsageSummary company={COMPANY_ENUM.REXEL} />,
  INVITE_USER: <InviteUser />,
  COPY_TRANSFER: <CopyTransfer />,
  UPDATE_CONTRACT: <UpdateContract />,
};

export const FORM_LABELS = {
  REGISTRATION_FORM: "User Registration (Free Trial)",
  PRO_CONVERSION_FORM: "Pro Conversion",
  ENTERPRISE_CONVERSION_FORM: "Enterprise Conversion",
  ENTERPRISE_CONVERSION_FOR_BEAM_FORM: "Enterprise Conversion for Beam",
  INCREASE_FREE_TRIAL_FORM: "Increase Free Trial ",
  FREE_PROPERTY_UPDATE_FORM: "Update Free Properties",
  USAGE_SUMMARY: "Download Usage Summary",
  USAGE_SUMMARY_USL: "Download Usage Summary for US Lawns",
  USAGE_SUMMARY_GG: "Download Usage Summary for Ground Guys",
  USAGE_SUMMARY_REXEL: "Download Usage Summary for Rexel",
  INVITE_USER: "Invite User",
  COPY_TRANSFER: "Copy transfer",
  UPDATE_CONTRACT: "Update Contract for Enterprise",
};

export const inputGroups = [
  {
    label: "Property Type",
    field: null,
    placeholder: "FP Left",
    extraClass: "disabled",
    readOnly: true,
  },
  { label: "Automated Aerial", field: "free_properties" },
  { label: "Automated Blueprint", field: "free_properties_blueprint" },
  { label: "Manual Aerial", field: "free_properties_manual" },
  { label: "Manual Blueprint", field: "free_properties_blueprint_manual" },
];

export const COUNTRY_CODE = {
  USA: "US",
  CANADA: "CA",
  AUSTRALIA: "AU",
  WORLD: "WR",
};

export const COUNTRY = {
  [COUNTRY_CODE.USA]: "United States",
  [COUNTRY_CODE.CANADA]: "Canada",
  [COUNTRY_CODE.WORLD]: "World",
  [COUNTRY_CODE.AUSTRALIA]: "Australia",
};

export const CHECHBOX_FIELDS = [
  "apply_role_level_restriction",
  "ignore_concurrency_limit",
  "show_optional_feature",
  "restrict_custom_report_creation",
  "restrict_report_switching",
  "ignore_size_limit",
  "ignore_draft_limit",
  "ignore_marketing_mailer",
  "is_pilot",
];

export const CHECHBOX_FIELDS_BEAM = ["is_pilot"];
// const Navigation Enum
export const Navigation = {
  0: "/",
  1: "/operations",
  2: "/company",
  3: "/enterprise-dashboard",
  4: "/copy-transfer",
};

export const GROUP_INPUT_DATA = [
  {
    key: "group-1",
    className: "input-group mb-3",
    showLabel: true,
    inputs: [
      {
        label: "Max Size Limit",
        valueKey: "max_size_limit",
        type: "text",
        ariaLabel: "max size limit",
        required: true,
      },
      {
        label: "Concurrency Aerial Value",
        valueKey: "concurrency_value",
        type: "text",
        ariaLabel: "concurrency limit",
        required: true,
      },
    ],
  },
  {
    key: "group-2",
    className: "input-group mb-3",
    showLabel: true,
    inputs: [
      {
        label: "Min Size Limit",
        valueKey: "min_measurement_size_unit",
        type: "text",
        ariaLabel: "min measurement size unit",
        required: true,
      },
      {
        label: "End Date",
        valueKey: "end_date",
        type: "date",
        ariaLabel: "end date",
        min: "2023-01-01T00:00",
        max: "2040-12-31T23:59",
        required: true,
      },
    ],
  },
  {
    key: "group-3",
    className: "input-group mb-3 ",
    showLabel: false,
    inputs: [
      {
        placeholder: "Subdomain (optional)",
        valueKey: "subdomain",
        type: "text",
        ariaLabel: "Subdomain",
      },
      {
        placeholder: "Callback URL (optional)",
        valueKey: "callback_url",
        type: "text",
        ariaLabel: "Callback URL",
      },
    ],
  },
  {
    key: "group-4",
    className: "input-group mb-3",
    showLabel: true,
    inputs: [
      {
        label: "Concurrency Construction Value",
        valueKey: "concurrency_value_construction",
        type: "number",
        ariaLabel: "concurrency value construction",
        min: 0,
        required: true,
      },
    ],
  },
];

export const GROUP_INPUT_DATA_BEAM = [
  {
    key: "group-1",
    className: "input-group mb-3",
    showLabel: true,
    inputs: [
      {
        label: "End Date",
        valueKey: "end_date",
        type: "date",
        ariaLabel: "end date",
        min: "2023-01-01T00:00",
        max: "2040-12-31T23:59",
        required: true,
      },
    ],
  },
  {
    key: "group-2",
    className: "input-group mb-3",
    showLabel: true,
    inputs: [
      {
        label: "Concurrency Construction Value",
        valueKey: "concurrency_value_construction",
        type: "number",
        ariaLabel: "concurrency value construction",
        min: 0,
        required: true,
      },
    ],
  },
];

export const USER_TYPES = {
  PRO: false,
  LITE: true,
};

export const USER_TYPES_ENUM = {
  [USER_TYPES.PRO]: "Pro",
  [USER_TYPES.LITE]: "Lite",
};

export const USER_ROLE = {
  TEAM_MEMBER: 2,
  TEAM_ADMIN: 3,
};

export const USER_ROLE_ENUM = {
  [USER_ROLE.TEAM_MEMBER]: "Team Member",
  [USER_ROLE.TEAM_ADMIN]: "Team Admin",
};

export const COMPANY_STATUS = {
  ACTIVE_PRO: 1,
  FREE_TRIAL: 2,
  FREE_TRIAL_EXPIRED: 3,
  PILOT_ENTERPRISE: 4,
  PILOT_EXPIRED: 5,
  PRO_EXPIRED: 6,
  ACTIVE_ENTERPRISE: 7,
  EXPIRED_ENTERPRISE: 8,
};

export const COMPANY_TYPE_NAME = {
  [COMPANY_STATUS.ACTIVE_PRO]: "Active Pro",
  [COMPANY_STATUS.FREE_TRIAL]: "Free Trial",
  [COMPANY_STATUS.FREE_TRIAL_EXPIRED]: "Free Trial Expired",
  [COMPANY_STATUS.PILOT_ENTERPRISE]: "Pilot Enterprise",
  [COMPANY_STATUS.PILOT_EXPIRED]: "Pilot Expired",
  [COMPANY_STATUS.PRO_EXPIRED]: "Pro Expired",
  [COMPANY_STATUS.ACTIVE_ENTERPRISE]: "Active Enterprise",
  [COMPANY_STATUS.EXPIRED_ENTERPRISE]: "Expired Enterprise",
};

export const RECORDS_PER_PAGE = 50;

export const BILLING_FREQUENCY_ENUM = {
  QUATERLY: 4,
  MONTHLY: 12,
  HALF_YEARLY: 2,
  ANNUAL: 1,
};

export const BILLING_FREQUENCY = {
  [BILLING_FREQUENCY_ENUM.QUATERLY]: "Quaterly",
  [BILLING_FREQUENCY_ENUM.HALF_YEARLY]: "Half Yearly",
  [BILLING_FREQUENCY_ENUM.MONTHLY]: "Monthly",
  [BILLING_FREQUENCY_ENUM.ANNUAL]: "Annual",
};

export const COMPANY_VERTICAL_ENUM = {
  FIELD_SERVICE: 1,
  GC: 2,
};

export const COMPANY_VERTICAL = {
  [COMPANY_VERTICAL_ENUM.FIELD_SERVICE]: "Field Service",
  [COMPANY_VERTICAL_ENUM.GC]: "General Construction",
};

export const INITIAL_FORM_DATA = {
  email: "",
  end_date: "",
  reports_given: [],
  default_report: "",
  ignore_marketing_mailer: true,
  ignore_draft_limit: true,
  ignore_size_limit: true,
  max_size_limit: 0,
  restrict_report_switching: true,
  restrict_custom_report_creation: true,
  ignore_concurrency_limit: false,
  show_optional_feature: false,
  concurrency_value: 2,
  min_measurement_size_unit: 0,
  apply_role_level_restriction: false,
  subdomain: "",
  callback_url: "",
  concurrency_value_construction: 0,
};

export const FIELDS_TO_REMOVE_FOR_BEAM = [
  "apply_role_level_restriction",
  "callback_url",
  "concurrency_value",
  "ignore_concurrency_limit",
  "ignore_draft_limit",
  "ignore_marketing_mailer",
  "ignore_size_limit",
  "min_measurement_size_unit",
  "restrict_custom_report_creation",
  "restrict_report_switching",
  "show_optional_feature",
  "subdomain",
  "start_date",
  "reports_given",
  "max_size_limit",
];

export const CONTRACT_INFO_FIELDS = {
  email: "",
  billing_email: "",
  company_name: "",
  address: "",
  city: "",
  zip: "",
  country: COUNTRY_CODE.USA,
  start_date: "",
  end_date: "",
  billing_frequency: BILLING_FREQUENCY_ENUM.QUATERLY,
  aerial_contract_amount: "",
  per_acre_cost: "",
  remeasurement_contract_amount: "",
  remeasurement_per_acre_cost: "",
  bpt_contract_amount: "",
  workable_sheet_cost: "",
  addendum_sheet_cost: "",
  manual_worksheet_cost: "",
  manual_per_acre_cost: "",
  manual_threshold_acres: "",
};

// Copy Transfer

export const REQUEST_TYPE_ENUM = {
  AERIAL: 1,
  CONSTRUCTION: 2,
};

export const REQUEST_TYPE = {
  [REQUEST_TYPE_ENUM.AERIAL]: "Aerial",
  [REQUEST_TYPE_ENUM.CONSTRUCTION]: "Construction",
};

export const BPT_TANSFER_TYPE_ENUM = {
  NOT_COPY_AND_CHARGE: 1,
  COPY_AND_NOT_CHARGE: 2,
  NOT_COPY_AND_NOT_CHARGE: 3,
};

export const AERIAL_TANSFER_TYPE_ENUM = {
  NOT_COPY_AND_CHARGE: 1,
  COPY_AND_NOT_CHARGE: 2,
  NOT_COPY_AND_NOT_CHARGE: 3,
};

export const TRANSFER_TYPE_ENUM = {
  PORTFOLIO_TRANSFER: 1,
  NON_PORTFOLIO: 2,
};

export const TRANSFER_TYPE = {
  [TRANSFER_TYPE_ENUM.PORTFOLIO_TRANSFER]: "Portfolio takeoff transfer",
  [TRANSFER_TYPE_ENUM.NON_PORTFOLIO]: "Non portfolio takeoff transfer",
};

export const GEOMETRY_TYPES = [
  {
    label: "POINT",
    value: 1,
  },
  {
    label: "LINE",
    value: 2,
  },
  {
    label: "POLYGON",
    value: 3,
  },
];

export const GROUP_TYPE = [
  {
    label: "NONE",
    value: 1,
  },
  {
    label: "SOFTSCAPE",
    value: 2,
  },
  {
    label: "HARDSCAPE",
    value: 3,
  },
  {
    label: "EDGE",
    value: 4,
  },
];

export const OPERATIONS_TAB_ENUM = {
  GENERATE_DELIVERY_SHEET: 0,
  CREATE_FEATURE: 1,
  PORTFOLIOS: 2,
};
export const COMPANIES_TAB_ENUM = {
  COMPANY_DETAILS: 0,
  COMPANY_USAGE: 1,
};

export const EXPORT_TYPE_ENUM = {
  FOLDER: 1,
  REQUESTS: 2,
  ORCHESTRATOR: 3,
};
export const COMPANY_TYPE_ENUM = {
  ENTERPRISE: 1,
  NON_ENTERPRISE: 2,
};

export const STATUS_ENUM = {
  ESTIMATION: 1,
  WAITING_FOR_CLIENT: 2,
  TO_DO: 3,
  IN_PROGRESS: 4,
  COMPLETED: 5,
  CANCELLED_BY_CLIENT: 6,
};

export const STATUS = {
  [STATUS_ENUM.WAITING_FOR_CLIENT]: "Waiting for client",
  [STATUS_ENUM.CANCELLED_BY_CLIENT]: "Cancelled by client",
  [STATUS_ENUM.ESTIMATION]: "Estimation",
  [STATUS_ENUM.TO_DO]: "To do",
  [STATUS_ENUM.IN_PROGRESS]: "In progress",
  [STATUS_ENUM.COMPLETED]: "Completed",
};

export const PORTFOLIO_REVIEW_ENUM = {
  FALCON: 1,
  FLOCK: 2,
};

export const LABEL_CONSTANTS = {
  [COMPANY_STATUS.FREE_TRIAL]: "",
  [COMPANY_STATUS.FREE_TRIAL_EXPIRED]: "",
  [COMPANY_STATUS.ACTIVE_PRO]: "Plan",
  [COMPANY_STATUS.PILOT_ENTERPRISE]: "Platform Access",
  [COMPANY_STATUS.PILOT_EXPIRED]: "Platform Access",
  [COMPANY_STATUS.PRO_EXPIRED]: "Plan",
  [COMPANY_STATUS.ACTIVE_ENTERPRISE]: "Platform Access",
  [COMPANY_STATUS.EXPIRED_ENTERPRISE]: "Platform Access",
};

export const COMPANY_STATUS_NEW_ENUM = {
  ACTIVE_PRO: 1,
  FREE_TRIAL: 2,
  FREE_TRIAL_EXPIRED: 3,
  PILOT_ENTERPRISE: 4,
  PILOT_EXPIRED: 5,
  PRO_EXPIRED: 6,
  ACTIVE_ENTERPRISE: 7,
  EXPIRED_ENTERPRISE: 8,
};

export const COMPANY_STATUS_NEW = {
  [COMPANY_STATUS_NEW_ENUM.ACTIVE_PRO]: "Active Pro",
  [COMPANY_STATUS_NEW_ENUM.FREE_TRIAL]: "Free Trial",
  [COMPANY_STATUS_NEW_ENUM.FREE_TRIAL_EXPIRED]: "Free Trial Expired",
  [COMPANY_STATUS_NEW_ENUM.PILOT_ENTERPRISE]: "Pilot Enterprise",
  [COMPANY_STATUS_NEW_ENUM.PILOT_EXPIRED]: "Pilot Expired",
  [COMPANY_STATUS_NEW_ENUM.PRO_EXPIRED]: "Pro Expired",
  [COMPANY_STATUS_NEW_ENUM.ACTIVE_ENTERPRISE]: "Active Enterprise",
  [COMPANY_STATUS_NEW_ENUM.EXPIRED_ENTERPRISE]: "Expired Enterprise",
};

export const SERVICE_TYPE = {
  1: "FIELD SERVICE",
  2: "GC",
};

export const COMPANY_TYPE = {
  [COMPANY_TYPE_ENUM.ENTERPRISE]: "Enterprise",
  [COMPANY_TYPE_ENUM.NON_ENTERPRISE]: "Non-Enterprise",
};
